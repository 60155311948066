<template>
  <div>
    <div class="main-title">课程管理</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head" >
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">课程</li>
          </ul>
        </div>
      </div>


      <div class="padding-t-sm padding-b-sm">
                 <span class="fl-l" >
                <el-input v-model.trim="name" placeholder="请输入名称"></el-input>
                </span>

        <span class="margin-l-sm fl-l" >
                    <el-select v-model="status" clearable placeholder="状态" size="small">
                        <el-option label="上架" value="1"> </el-option>
                        <el-option label="下架" value="0">
                        </el-option>
                    </el-select>
                </span>


        <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

        <span class=" fl-r">
                    <el-button type="primary" size="small" @click="dialogVisible = true">新建课程</el-button>
                </span>

        <div style="clear: both"></div>

      </div>


      <div class="table-content">

        <el-table
            :header-cell-style="{padding:0}"
            :cell-class-name="rowItem"
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @current-change="handleCurrentChange"
            v-loading="loading"
            style="width: 100%;background: #fff;">
          <el-table-column
              label="ID"
              property="id"
              width="80">
          </el-table-column>
          <el-table-column
              property="index"
              label="名称">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>

          <el-table-column
              property="index"
              label="助教昵称">
            <template slot-scope="scope">
              <el-tag class="margin-r-sm margin-b-sm" size="mini" v-for="(item,index) in scope.row.tname" :key="index">{{item}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              property="index"
              label="助教uid">
            <template slot-scope="scope">
              <el-tag type="success" class="margin-r-sm margin-b-sm" size="mini" v-for="(item,index) in scope.row.teachers" :key="index">{{item}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column
              property="created_at"
              label="创建时间"
              width="200">
          </el-table-column>
          <el-table-column
              property="address"
              label="状态" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"><i></i>启用</span>
              <span v-if="scope.row.status == 0" class="state-down"><i></i>未启用</span>
            </template>
          </el-table-column>
          <el-table-column
              property="address"
              label="操作">
            <template slot-scope="scope">
              <span><el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link></span> -
              <span>
                  <el-link v-if="scope.row.status == 1" type="primary" @click="setStatus(scope.row,scope.$index)">下架</el-link>
                  <el-link v-if="scope.row.status == 0" type="primary" @click="setStatus(scope.row,scope.$index)">上架</el-link>
              </span>
            </template>
          </el-table-column>
        </el-table>

      </div>

      <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                        small
                        background
                        :current-page="page"
                        :page-sizes="[10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="setPageSize"
                        @current-change="setPage"
                        :total="total">
                </el-pagination>
                </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        :show-close="false"
        append-to-body
        width="500px">
      <div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="课程名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="助教老师uid" v-for="(teacher,index) in form.teachers" :key="index">
           <div style="display: flex;justify-content: space-between;align-items: center">
             <el-input v-model.number="form.teachers[index]"></el-input>
             <el-button class="margin-l-sm" style="margin-top: 2px" type="danger" size="mini" @click="delTeacher(index)">删除</el-button>
           </div>
          </el-form-item>
          <el-form-item label="" >
            <el-button size="mini" @click="addTeacher">添加助教老师</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
    <el-button type="primary" @click="submitCourse" size="mini">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "template-list",
  data(){
    return {
      dialogVisible:false,
      status:'',
      page:1,
      pageSize:10,
      total:0,
      loading:false,
      imageUrl:config.imageUrl,
      value: '',
      value2: '',
      tableData: [
      ],
      form:{
        id:'',
        name:'',
        teachers:['']
      },
      currentRow: null,
      name:''
    }
  },
  methods:{
    ...mapActions("courseScheduling", ["getCourseSchedulingCourse",'addCourseSchedulingCourse','setCourseStatus']),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({row, rowIndex}) {
      return '{padding:0}';
    },
    rowItem(){
      return 'row-item';
    },
    search(){
      this.tableData = []
      this.page = 1
      this.getList()
    },
    getList(){
      this.loading = true
      let data = {
        name:this.name,
        page:this.page,
        pageSize:this.pageSize,
        status:this.status,
      }
      this.getCourseSchedulingCourse(data).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    setStatus(row, rowIndex){
      let _this = this
      let data = {
        id:row.id,
        status:!row.status,
      }

      this.loading = true
      this.setCourseStatus(data).then(success => {
        _this.tableData[rowIndex].status = success.data.status

        _this.$message({
          message: '设置成功',
          type: 'success'
        });
        this.loading = false
      },error => {
        _this.$message({
          message: '设置失败',
          type: 'error'
        });
        this.loading = false
      })
    },
    deleted(row, rowIndex){
      let _this = this
      this.loading = true
      this.delCourse(row.id).then(success => {
        _this.getList()
        _this.$message({
          message: '删除成功',
          type: 'success'
        });
      },error => {
        _this.$message({
          message: '删除失败',
          type: 'error'
        });
      })
    },
    setPageSize(pageSize){
      this.pageSize = pageSize
      this.getList()
    },
    setPage(page){
      this.page  = page
      this.getList()
    },
    courseDetail(row){
      this.$router.push('/course/detail/'+row.id+'/manage')
    },
    editDetail(row){
      this.form.id = row.id
      this.form.name = row.name
      this.form.teachers = row.teachers
      this.dialogVisible = true
    },
    addTeacher(){
      this.form.teachers.push('');
    },
    delTeacher(index){
      this.form.teachers.splice(index,1);
    },
    submitCourse(){
      this.addCourseSchedulingCourse(this.form).then(res => {
        if(res.ret ==0){
          this.$message.success('添加成功')
          this.dialogVisible = false
          this.getList()
        }
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style>

</style>
